export default {
  "logo": {
    "name": "Tik tak",
    "msg": "Objednací centrum Tiktok",
    "wz": "URL:"
  },
  "head": { "Language": "čeština" },
  "menu": {
    "home": "přední strana",
    "order": "Objednat",
    "trading": "obchod",
    "earnings": "tým",
    "mine": "uživatel"
  },
  "back": {
    "appDownload": "Stahování aplikace",
    "help": "Pomoc",
    "order": "Objednat",
    "trading": "obchod",
    "income": "tým",
    "revenueDetails": "Detail",
    "Withdrawal": "ustoupit",
    "Recharge": "doplňovat",
    "Invitefriends": "pozvat přátele",
    "personalinformation": "osobní informace",
    "gywm": "o nás",
    "kf": "služby zákazníkům"
  },
  "loading": {
    "loadingText": "načítání...",
    "finishedText": "Už už",
    "loadingText2": "Odpovídající ...",
    "loadingLogin": "Přihlášení ...",
    "loadingSign": "Registrovat ...",
    "confirmOrder": "v transakci ...",
    "ldg1": "Při stažení ..."
  },
  "Toast": { "copy": "Replikace", "ts": "Vyberte prosím jazyk" },
  "login": {
    "title": "Ahoj!",
    "msg": "Přihlaste se ke svému účtu",
    "phone": "účet",
    "phoneCode": "Kód oblasti",
    "password": "Heslo",
    "remember": "zapamatuj si mě",
    "forget": "zapomenout na heslo?",
    "loginBtn": "Přihlásit se",
    "noAccount": "Žádný účet?",
    "goRegister": "Registrovat",
    "lg1": "Další přihlášení k účtu"
  },
  "sign": {
    "phone": "Telefonní číslo",
    "phoneCode": "Kód oblasti",
    "Nickname": "Nick jméno",
    "code": "Ověřovací kód",
    "sendCode": "Odeslat ověřovací kód",
    "password": "Heslo",
    "confirmPassword": "Potvrďte heslo",
    "recommendationCode": "Kód doporučení",
    "protocol": "Registr znamená, že souhlasíte s našimi podmínkami",
    "registerBtn": "Registrovat",
    "Existing": "Stávající účet?",
    "goLogin": "Přihlásit se",
    "rule": {
      "phone": "Účet nemůže být prázdný, formát účtu je nesprávný",
      "nickname": "Přezdívka by měla být vyplněna",
      "password": "Heslo nemůže být prázdné, heslo je minimální 6 číslic a heslo je nekonzistentní.",
      "code": "Ověřovací kód musí být vyplněn",
      "isAgree": "Nejprve prosím zkontrolujte souhlas"
    }
  },
  "home": {
    "cumulativeIncome": "Kumulativní příjem",
    "notification": "oznámit",
    "moduleTitle1": "Moje služba",
    "moduleTitle2": "Partner",
    "title": "oznámit"
  },
  "download": {
    "TIKTOKSHOP": "Obchod Tiktok",
    "AndroidDownload": "Android ke stažení",
    "iOSDownload": "Apple Download"
  },
  "help": {
    "bz2": "běžný problém",
    "bz3": "Obchodní pravidla",
    "bz4": "Jak vybírat peníze",
    "bz5": "Jak se dobít",
    "bz6": "1. Co istiktokshop?",
    "bz7": "Tiktokshop je marketingová propagační společnost, která pomáhá obchodníkům v internetovém obchodě Tiktokshop z celého světa, aby získaly více prodeje objednávek a zvýšily údaje o prohlížení z online obchodů Tiktokshop. Zavázali jsme se zřídit třístranné ziskové marketingové model mezi Tiktokshop a obchodníky a spotřebiteli. Kombinace nejnovější technologie blockchainu P2P rychle propojuje spotřebitele a obchodníky prostřednictvím USDT (TRC20, ERC20). Registrovaní uživatelé mohou získat provizi objednávek, zatímco obchodníci mohou zvýšit údaje o prodeji v obchodě. Je to nejnovější model zisku v modelu internetu blockchain!",
    "bz8": "2. Jak pracovat ontiktokshop?",
    "bz9": "Prostřednictvím denní zpětné vazby je třeba vylepšit produkty, které je třeba vylepšit, je třeba vylepšit produkty, které je třeba vylepšit. Uživatelé platí částku objednávky prostřednictvím blockchain USDT a získají denní provize.",
    "bz10": "3. Proč existuje rozdíl v ceně stejné měny ve dvou transakcích?",
    "bz11": "Cenové rozdíly jsou způsobeny všemi faktory, které nepřispívají ke svobodě měny, včetně rychlosti převodu měny, podmínek sítě, omezení přístupu k měně, lidí v různých regionech a dokonce i typy transakcí poskytovaných burzou. Stejná měna proto může způsobit cenové rozdíly v různých transakcích.",
    "bz12": "4. Investiční zisk?",
    "bz13": "Čím vyšší je cena, tím vyšší je zisk. Současně Tiktokshop náhodně přiděluje velké příkazy komise.",
    "bz14": "Systém každý den automaticky generuje a distribuuje 60 komoditních objednávek uživatelům. Velké příkazy komise jsou náhodně distribuovány.",
    "bz15": "USDT může být staženo po 60 objednávkách. (Pokud 60 objednávek není dokončeno, systém se automaticky zastaví až do přebytku objednávky dne)",
    "bz16": "Každý účet může být navázán pouze na účet USDT.",
    "bz17": "Po dokončení denního doručení můžete normálně stáhnout.",
    "bz18": "Před dobití naplňte prosím adresu pro výběr (podpora TRC-20) USDT.",
  "bz19": "Existují zakázané chování, jako je vzájemné dobití nebo bankovní převody, a převody mezi souvisejícími účty jsou podezřelé z zločinného popadnutí a zjistilo se, že chování bude potrestáno číslem účtu"

  },
  "order": {
    "incomplete": "nehotový",
    "completed": "dokončeno",
    "orderNumber": "číslo objednávky",
    "transactionTime": "Transakční hodina",
    "price": "Množství",
    "expectedReturns": "Očekávaný návrat",
    "submit": "Odeslat objednávku"
  },
  "trading": {
    "accountBalance": "Zůstatek na účtu",
    "introduce": "Úvod transakce",
    "freeze": "Dokončené číslo",
    "walletBalance": "Celkový úkol",
    "startMatching": "Začněte odpovídat",
    "yield": "Dnešní výhody",
    "orderNumber": "číslo objednávky",
    "transactionTime": "Transakční hodina",
    "price": "Množství",
    "expectedReturns": "Očekávaný návrat",
    "startTrading": "Začít obchodovat",
    "cancel": "zrušení",
    "ts": "Nedostatečná rovnováha, stále chudá",
    "msg": "Tiktokshop používá zpětnou vazbu od Tiktokshop každý den k zobrazení produktů, které je třeba zlepšit pro prodej Tiktokshop. Pokud uživatel potřebuje pouze kliknout na objednávku, systém automaticky vygeneruje podpis objednávky. Uživatel platí částku objednávky prostřednictvím blockchainu USDT a může pokaždé získat více než 0,6%provize a systém náhodně distribuuje velké odměny.",
    "ts2": "náznak",
    "ts3": "Nedostatečná rovnováha",
    "ts4": "Jít na dobití",
    "ts5": "zrušení"
  },
  "income": {
    "totalRevenue": "Zůstatek",
    "residualIncome": "komise",
    "claim": "dostávat",
    "moduleTitle": "můj tým",
    "headcount": "celkem lidé",
    "totalRecharge": "Celkové dobití",
    "grade": "uživatel",
    "numberOfPeople": "Příspěvek (množství)",
    "income": "Stát",
    "viewDetails": "Zobrazit podrobnosti",
    "task_num": "nehotový",
    "task_num2": "dokončeno"
  },
  "revenueDetails": {
    "totalRevenue": "Celkové příjmy",
    "headcount": "celkem lidé",
    "totalRecharge": "Celkové dobití",
    "userLevel1": "Uživatelská úroveň 1",
    "userLevel2": "Uživatelská úroveň 2",
    "userLevel3": "Uživatelská úroveň 3",
    "Nickname": "Nick jméno",
    "phone": "Telefonní číslo",
    "Recharge": "doplňovat",
    "income": "příjem",
    "date": "Čas registrace"
  },
  "user": {
    "Myaccount": "můj účet",
    "Withdrawal": "ustoupit",
    "Recharge": "doplňovat",
    "InviteFriend": "Pozvi své přátele",
    "InviteFriendTxt": "Za každý zisk získaný přáteli získáte odpovídající proporcitu proporce",
    "InviteFriendBtn": "Pozvěte hned",
    "MyInformation": "osobní informace",
    "FundsDetails": "Detaily kapitálu",
    "RechargeRecord": "Dobití záznam",
    "WithdrawalRecord": "Výběr záznamů",
    "Language": "Jazyk",
    "Logout": "odhlásit se"
  },
  "Withdrawal": {
    "address": "Adresa pro výběr",
    "placeholder1": "Adresa pro výběr",
    "availableBalance": "Dostupná částka",
    "placeholder2": "Částka výběru",
    "actualBalance": "Vlastně",
    "placeholder3": "Vlastně",
    "password": "Heslo",
    "placeholder4": "Zadejte 6-16bitové heslo",
    "submit": "Předložit",
    "amount": "Ne větší než dostupné částky",
    "w1": "Manipulační poplatek",
    "ts1": "Získejte adresu pro stažení",
    "ts2": "Zadejte částku výběru"
  },
  "Recharge": {
    "qrcode": "Napření kódu skenování",
    "copy": "Kliknutím zkopírujte",
    "cotakecarepy": "Všimněte si, že související úvaha je použití vaší obrazovky nebo stránky nemovitosti",
    "ts1": "Dobíjení množství",
    "ts2": "Jiná částka",
    "ts3": "Tak určitě",
    "ts4": "Nejprve připojte peněženku",
    "ts5": "Vyberte prosím částku dobití",
    "ts6": "V dobití ..."
  },
  "Invitefriends": {
    "inviteCode": "Kód pozvánky",
    "inviteTxt": "Pozvěte přátele, užijte si velké množství příjmů, pozvěte prosím",
    "copy": "Kliknutím zkopírujte",
    "Rebaterules": "Sleva",
    "RebaterulesMsg": "Musí být splněny následující podmínky",
    "scale": "Pravidla poměru provize jsou následující",
    "Userlevel": "úroveň uživatele",
    "Rules": "pravidlo",
    "Commissionratio": "Sazba provize",
    "ps": "Komise je urovnána s USDT, která může být kdykoli stažena do vaší peněženky"
  },
  "personalinformation": {
    "Nickname": "Nick jméno",
    "phone": "účet",
    "Loginpassword": "přihlašovací heslo",
    "address": "Adresa pro výběr",
    "modify": "Revidovat",
    "Confirm": "Tak určitě"
  },
  "fundDetails": {
    "amount": "Množství",
    "revenue": "příjem",
    "time": "čas",
    "withdrawal": "ustoupit",
    "recharge": "doplňovat",
    "type": {
      "1": "doplňovat",
      "2": "ustoupit",
      "3": "Sběr provize",
      "4": "příjem",
      "5": "Ustoupit",
      "6": "čekající",
      "7": "dokončeno",
      "8": "odmítnuto"
    }
  },
  "language": {
    "placeholder": "Zadejte prosím kód mezinárodní oblasti",
    "Confirm": "Tak určitě",
    "cancel": "zrušení"
  },
  "request": {
    "loadingText": "načítání...",
    "Serverexception": "Abnormality serveru"
  },
  "newLang": { "a1": "Rovnováha je špatná", "a2": "Aktuální číslo úkolu", "a3": "Nejprve přejděte na adresu odběru závazné", "a4": "Nedostatečná rovnováha", "a5": "Zda se dobít" },
  "newLang2": {
    "a1": "Pro vazebnou adresu kontaktujte prosím zákaznický servis a upravte",
    "a2": "Je třeba dobít",
    "a3": "Kontaktujte prosím zákaznický servis a upravte heslo"
  }
}
