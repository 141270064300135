export default {
  "logo": { "name": "Tiktok", "msg": "Tiktok Order Center", "wz": "URL:" },
  "head": { "Language": "Nederlands" },
  "menu": {
    "home": "voorpagina",
    "order": "Volgorde",
    "trading": "handel",
    "earnings": "team",
    "mine": "gebruiker"
  },
  "back": {
    "appDownload": "App downloaden",
    "help": "hulp",
    "order": "Volgorde",
    "trading": "handel",
    "income": "team",
    "revenueDetails": "Detail",
    "Withdrawal": "terugtrekken",
    "Recharge": "aanvullen",
    "Invitefriends": "nodig vrienden uit",
    "personalinformation": "persoonlijke informatie",
    "gywm": "over ons",
    "kf": "klantenservice"
  },
  "loading": {
    "loadingText": "bezig met laden...",
    "finishedText": "Niet meer",
    "loadingText2": "Bij elkaar passen ...",
    "loadingLogin": "Inloggen...",
    "loadingSign": "Register ...",
    "confirmOrder": "In transactie ...",
    "ldg1": "In de terugtrekking ..."
  },
  "Toast": { "copy": "Replicatie", "ts": "Selecteer taal" },
  "login": {
    "title": "Hallo!",
    "msg": "Log in op jouw account",
    "phone": "rekening",
    "phoneCode": "Regio code",
    "password": "wachtwoord",
    "remember": "Onthoud me",
    "forget": "het wachtwoord vergeten?",
    "loginBtn": "Log in",
    "noAccount": "Geen account?",
    "goRegister": "Register",
    "lg1": "Andere Account Login"
  },
  "sign": {
    "phone": "Telefoonnummer",
    "phoneCode": "Regio code",
    "Nickname": "Bijnaam",
    "code": "Verificatie code",
    "sendCode": "Stuur de verificatiecode",
    "password": "wachtwoord",
    "confirmPassword": "bevestig wachtwoord",
    "recommendationCode": "Aanbevelingscode",
    "protocol": "Register betekent dat u akkoord gaat met onze algemene voorwaarden",
    "registerBtn": "register",
    "Existing": "Bestaand account?",
    "goLogin": "Log in",
    "rule": {
      "phone": "Het account kan niet leeg zijn, het accountformaat is onjuist",
      "nickname": "De bijnaam moet worden gevuld",
      "password": "Het wachtwoord kan niet leeg zijn, het wachtwoord is minimaal 6 cijfers en het wachtwoord is inconsistent.",
      "code": "Verificatiecode moet worden ingevuld",
      "isAgree": "Controleer eerst de toestemming"
    }
  },
  "home": {
    "cumulativeIncome": "Cumulatief inkomen",
    "notification": "op de hoogte stellen",
    "moduleTitle1": "Mijn service",
    "moduleTitle2": "Partner",
    "title": "op de hoogte stellen"
  },
  "download": {
    "TIKTOKSHOP": "Tiktok -winkel",
    "AndroidDownload": "Android -download",
    "iOSDownload": "Apple download"
  },
  "help": {
    "bz2": "vaak voorkomend probleem",
    "bz3": "Handelsregels",
    "bz4": "Hoe je geld kunt opnemen",
    "bz5": "Hoe u oplaadt",
    "bz6": "1. Welke Istiktokshop?",
    "bz7": "Tiktokshop is een marketingpromotiebedrijf dat Tiktokshop online winkelhandelaren van over de hele wereld helpt om meer bestelverkopen te krijgen en de kijkgegevens van Tiktokshop online winkels te vergroten. We zijn toegewijd aan het opzetten van een winstmarketingmodel met drie partijen tussen Tiktokshop en handelaren en consumenten. De combinatie van de nieuwste P2P -blockchain -technologie, verbind consumenten en handelaren snel via USDT (TRC20, ERC20). Geregistreerde gebruikers kunnen de commissie van bestellingen krijgen, terwijl verkopers de verkoopgegevens van de winkel kunnen verhogen. Het is het nieuwste winstmodel in het internetblockchain -model!",
    "bz8": "2. Hoe werk je ontiktokshop?",
    "bz9": "Door de dagelijkse feedback van Tiktokshop moeten producten die moeten worden verbeterd bij de verkoop van Tiktokshop, worden verbeterd. Gebruikers betalen het bestelbedrag via de Blockchain USDT en krijgen dagelijkse commissies.",
    "bz10": "3. Waarom is er een verschil in de prijs van dezelfde valuta in de twee transacties?",
    "bz11": "Prijsverschillen worden veroorzaakt door alle factoren die niet bevorderlijk zijn voor de vrijheid van valuta, inclusief de snelheid van valutaoverdracht, netwerkomstandigheden, beperkingen van de toegang tot valuta, mensen in verschillende regio's en zelfs de handelstypen en transacties die door de beurs worden verstrekt. Daarom kan dezelfde valuta prijsverschillen in verschillende transacties veroorzaken.",
    "bz12": "4. Investeringswinst?",
    "bz13": "Hoe hoger de prijs die u krijgt, hoe hoger de winst die u krijgt. Tegelijkertijd wijst Tiktokshop willekeurig grote commissiebevelen toe.",
    "bz14": "Elke dag genereert en distribueert het systeem automatisch 60 grondstofopdrachten aan de gebruiker. Grote commissiebevelen worden willekeurig verdeeld.",
    "bz15": "USDT kan na 60 bestellingen worden ingetrokken. (Als de 60 bestellingen niet zijn voltooid, stopt het systeem automatisch tot het bedrag van de overtollige bestelling van de dag)",
    "bz16": "Elk account kan alleen worden gebonden aan een USDT -account.",
    "bz17": "Na het voltooien van de levering van de dagelijkse bestelling kunt u zich normaal terugtrekken.",
    "bz18": "Voordat u oplaadt, bindt u uw intrekkingsadres (ondersteuning van TRC-20) USDT-adres.",
  "bz19": "Er zijn verboden gedragingen zoals wederzijds opladen of bankoverdrachten en uitwisselingsoverdrachten tussen gerelateerde rekeningen."

  },
  "order": {
    "incomplete": "ongedaan gemaakt",
    "completed": "voltooid",
    "orderNumber": "bestellingsnummer",
    "transactionTime": "transactieuur",
    "price": "Hoeveelheid",
    "expectedReturns": "Verwachte terugkomst",
    "submit": "Bevestig bestelling"
  },
  "trading": {
    "accountBalance": "Rekeningsaldo",
    "introduce": "Transactie -introductie",
    "freeze": "Voltooid nummer",
    "walletBalance": "Totale taak",
    "startMatching": "Begin met matching",
    "yield": "De voordelen van vandaag",
    "orderNumber": "bestellingsnummer",
    "transactionTime": "transactieuur",
    "price": "Hoeveelheid",
    "expectedReturns": "Verwachte terugkomst",
    "startTrading": "Begin met handelen",
    "cancel": "Annuleren",
    "ts": "Onvoldoende balans, nog steeds slecht",
    "msg": "Tiktokshop gebruikt elke dag feedback van Tiktokshop om producten weer te geven die moeten worden verbeterd voor de verkoop van Tiktokshop. Als de gebruiker alleen op de bestelling hoeft te klikken, genereert het systeem automatisch een bestelhandtekening. De gebruiker betaalt het bestelbedrag via de USDT -blockchain en kan elke keer meer dan 0,6%van de commissie krijgen, en het systeem verdeelt willekeurig grote beloningsorders.",
    "ts2": "tips",
    "ts3": "Onvoldoende balans",
    "ts4": "Ga opladen",
    "ts5": "Annuleren"
  },
  "income": {
    "totalRevenue": "Evenwicht",
    "residualIncome": "commissie",
    "claim": "ontvangen",
    "moduleTitle": "mijn team",
    "headcount": "totaal mensen",
    "totalRecharge": "Totaal opladen",
    "grade": "gebruiker",
    "numberOfPeople": "Bijdrage (kwantiteit)",
    "income": "staat",
    "viewDetails": "Bekijk details",
    "task_num": "ongedaan gemaakt",
    "task_num2": "voltooid"
  },
  "revenueDetails": {
    "totalRevenue": "De totale omzet",
    "headcount": "totaal mensen",
    "totalRecharge": "Totaal opladen",
    "userLevel1": "Gebruikersniveau 1",
    "userLevel2": "Gebruikersniveau 2",
    "userLevel3": "Gebruikersniveau 3",
    "Nickname": "Bijnaam",
    "phone": "Telefoonnummer",
    "Recharge": "aanvullen",
    "income": "inkomen",
    "date": "Registratietijd"
  },
  "user": {
    "Myaccount": "mijn rekening",
    "Withdrawal": "terugtrekken",
    "Recharge": "aanvullen",
    "InviteFriend": "Nodig je vrienden uit",
    "InviteFriendTxt": "Voor elke winst die door vrienden wordt verdiend, krijgt u de Correspondering Proport Commission",
    "InviteFriendBtn": "Nodig nu uit",
    "MyInformation": "persoonlijke informatie",
    "FundsDetails": "Kapitaalgegevens",
    "RechargeRecord": "Oplaadrecord",
    "WithdrawalRecord": "Intrekkingen record",
    "Language": "taal",
    "Logout": "afmelden"
  },
  "Withdrawal": {
    "address": "Terugtrekking",
    "placeholder1": "Terugtrekking",
    "availableBalance": "beschikbaar saldo",
    "placeholder2": "Opnamebedrag",
    "actualBalance": "Eigenlijk",
    "placeholder3": "Eigenlijk",
    "password": "wachtwoord",
    "placeholder4": "Voer het 6-16-bit wachtwoord in",
    "submit": "indienen",
    "amount": "Niet groter dan beschikbare bedragen",
    "w1": "Verwerkingskosten",
    "ts1": "Haal het intrekkingsadres op",
    "ts2": "Voer het opnamebedrag in"
  },
  "Recharge": {
    "qrcode": "Scancode opladen",
    "copy": "Klik om te kopiëren",
    "cotakecarepy": "Let op, een gerelateerde overweging is om uw scherm- of pagina -onroerend goed te gebruiken",
    "ts1": "Het bedrag opladen",
    "ts2": "Ander bedrag",
    "ts3": "Zeker",
    "ts4": "Sluit de portemonnee eerst aan",
    "ts5": "Selecteer het oplaadbedrag",
    "ts6": "In opladen ..."
  },
  "Invitefriends": {
    "inviteCode": "Uitnodigingscode",
    "inviteTxt": "Vrienden uitnodigen, genieten van een grote hoeveelheid inkomsten, nodigt u uit",
    "copy": "Klik om te kopiëren",
    "Rebaterules": "Korting",
    "RebaterulesMsg": "Aan de volgende voorwaarden moet worden voldaan",
    "scale": "REGELS VAN COMMASICE RATIO ZIJN ALS VOLGEN",
    "Userlevel": "gebruikers level",
    "Rules": "regel",
    "Commissionratio": "Commissie tarief",
    "ps": "De commissie is geregeld met USDT, die op elk moment in uw portemonnee kan worden ingetrokken"
  },
  "personalinformation": {
    "Nickname": "Bijnaam",
    "phone": "rekening",
    "Loginpassword": "login wachtwoord",
    "address": "Terugtrekking",
    "modify": "Herzien",
    "Confirm": "Zeker"
  },
  "fundDetails": {
    "amount": "Hoeveelheid",
    "revenue": "inkomen",
    "time": "tijd",
    "withdrawal": "terugtrekken",
    "recharge": "aanvullen",
    "type": {
    "1": "aanvullen",
    "2": "terugtrekken",
    "3": "Commissie -inzameling",
    "4": "inkomen",
    "5": "Terugtrekken",
    "6": "In behandeling",
    "7": "voltooid",
    "8": "afgewezen"
  }
  },
  "language": {
    "placeholder": "Voer het internationale netnummer in",
    "Confirm": "Zeker",
    "cancel": "Annuleren"
  },
  "request": {
    "loadingText": "bezig met laden...",
    "Serverexception": "Serverafwijkingen"
  },
  "newLang": { "a1": "Het evenwicht is slecht", "a2": "Huidig ​​taaknummer","a3": "Ga eerst naar het bindende intrekkingsadres","a4": "Onvoldoende balans", "a5": "Of je oplaadt" },
  "newLang2": {
    "a1": "Neem voor het bindende adres contact op met de klantenservice om te wijzigen",
    "a2": "Moeten opladen",
    "a3": "Neem contact op met de klantenservice om het wachtwoord te wijzigen"
  }
}
