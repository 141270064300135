export default  {
  "logo": { "name": "Tiktok", "msg": "Tiktok Order Center", "wz": "URL:" },
  "head": { "Language": "dansk" },
  "menu": {
    "home": "Forside",
    "order": "Bestille",
    "trading": "handle",
    "earnings": "hold",
    "mine": "bruger"
  },
  "back": {
    "appDownload": "App download",
    "help": "Hjælp",
    "order": "Bestille",
    "trading": "handle",
    "income": "hold",
    "revenueDetails": "Detalje",
    "Withdrawal": "trække sig tilbage",
    "Recharge": "Top op",
    "Invitefriends": "Inviter venner",
    "personalinformation": "personlig information",
    "gywm": "om os",
    "kf": "kunde service"
  },
  "loading": {
    "loadingText": "Indlæser...",
    "finishedText": "Ikke mere",
    "loadingText2": "Matchende ...",
    "loadingLogin": "logger ind...",
    "loadingSign": "Tilmeld ...",
    "confirmOrder": "I transaktion ...",
    "ldg1": "I tilbagetrækningen ..."
  },
  "Toast": { "copy": "Replikation", "ts": "Vælg sprog" },
  "login": {
    "title": "Hej!",
    "msg": "Log ind på din konto",
    "phone": "konto",
    "phoneCode": "Regionkode",
    "password": "adgangskode",
    "remember": "Husk mig",
    "forget": "Glem adgangskoden?",
    "loginBtn": "Log på",
    "noAccount": "Ingen konto?",
    "goRegister": "Tilmeld",
    "lg1": "Andet konto login"
  },
  "sign": {
    "phone": "Telefonnummer",
    "phoneCode": "Regionkode",
    "Nickname": "Nick Name",
    "code": "Verifikationskode",
    "sendCode": "Send verifikationskoden",
    "password": "adgangskode",
    "confirmPassword": "Bekræft kodeord",
    "recommendationCode": "Anbefalingskode",
    "protocol": "Registrer betyder, at du er enig i vores vilkår og betingelser",
    "registerBtn": "Tilmeld",
    "Existing": "Eksisterende konto?",
    "goLogin": "Log på",
    "rule": {
      "phone": "Kontoen kan ikke være tom, kontoformatet er forkert",
      "nickname": "Kælenavn skal udfyldes",
      "password": "Adgangskoden kan ikke være tom, adgangskoden er minimal 6 -cifre, og adgangskoden er inkonsekvent.",
      "code": "Verifikationskode skal udfyldes",
      "isAgree": "Kontroller venligst samtykke først"
    }
  },
  "home": {
    "cumulativeIncome": "Kumulativ indkomst",
    "notification": "underrette",
    "moduleTitle1": "Min service",
    "moduleTitle2": "Partner",
    "title": "underrette"
  },
  "download": {
    "TIKTOKSHOP": "Tiktok Store",
    "AndroidDownload": "Android download",
    "iOSDownload": "Apple Download"
  },
  "help": {
    "bz2": "fælles problem",
    "bz3": "Handelsregler",
    "bz4": "Hvordan man trækker penge tilbage",
    "bz5": "Hvordan man genoplades",
    "bz6": "1. Hvad istiktokshop?",
    "bz7": "Tiktokshop er et marketingfremmefirma, der hjælper Tiktokshop online butikhandlere fra hele verden for at få mere ordre -salg og øge visningsdataene fra Tiktokshop online butikker. Vi er forpligtet til at etablere en tre -party profitmarkedsføringsmodel mellem Tiktokshop og købmænd og forbrugere. Ved at kombinere den nyeste P2P -blockchain -teknologi forbinder hurtigt forbrugere og købmænd gennem USDT (TRC20, ERC20). Registrerede brugere kan få ordrer, mens købmænd kan øge butikens salgsdata. Det er den seneste profitmodel i Internet Blockchain -modellen!",
    "bz8": "2. Hvordan arbejder jeg Ontiktokshop?",
    "bz9": "Gennem den daglige feedback fra Tiktokshop skal produkter, der skal forbedres ved Tiktokshop -salget, kun forbedres. Brugere betaler ordrebeløbet gennem blockchain USDT og får daglige provisioner.",
    "bz10": "3. Hvorfor er der en forskel i prisen på den samme valuta i de to transaktioner?",
    "bz11": "Prisforskelle er forårsaget af alle faktorer, der ikke er befordrende for valutafriheden, herunder hastigheden for valutaoverførsel, netværksbetingelser, valutaknusbegrænsninger, mennesker i forskellige regioner og endda handelstyper og transaktioner leveret af udvekslingen. Derfor kan den samme valuta forårsage prisforskelle i forskellige transaktioner.",
    "bz12": "4. Investeringsresultat?",
    "bz13": "Jo højere pris du får, jo højere er den fortjeneste, du får. På samme tid tildeler TiKtokshop tilfældigt store kommissionsordrer.",
    "bz14": "Hver dag genererer og distribuerer systemet automatisk 60 råvareordrer til brugeren. Store provisionsordrer er tilfældigt distribueret.",
    "bz15": "USDT kan trækkes tilbage efter 60 ordrer. (Hvis de 60 ordrer ikke er afsluttet, stopper systemet automatisk, indtil dagens overskydende rækkefølge)",
    "bz16": "Hver konto kan kun være bundet til en USDT -konto.",
    "bz17": "Efter at have afsluttet den daglige ordreudbyder, kan du trække sig normalt ud.",
    "bz18": "Før opladning skal du binde din tilbagetrækningsadresse (Support TRC-20) USDT-adresse.",
  "bz19": "Der er forbudt adfærd såsom gensidig opladning eller bankoverførsler, og udvekslingsoverførsler mellem relaterede konti."

  },
  "order": {
    "incomplete": "Forslået",
    "completed": "afsluttet",
    "orderNumber": "Ordrenummer",
    "transactionTime": "Transaktionstid",
    "price": "Beløb",
    "expectedReturns": "Forventet afkast",
    "submit": "Indsend ordre"
  },
  "trading": {
    "accountBalance": "Kontosaldo",
    "introduce": "Transaktion Introduktion",
    "freeze": "Afsluttet nummer",
    "walletBalance": "Total opgave",
    "startMatching": "Begynd at matche",
    "yield": "Dagens fordele",
    "orderNumber": "Ordrenummer",
    "transactionTime": "Transaktionstid",
    "price": "Beløb",
    "expectedReturns": "Forventet afkast",
    "startTrading": "Begynd handel",
    "cancel": "Afbestille",
    "ts": "Utilstrækkelig balance, stadig dårlig",
    "msg": "Tiktokshop bruger feedback fra Tiktokshop hver dag til at vise produkter, der skal forbedres til TIKTOKSHOP -salget. Hvis brugeren kun skal klikke på ordren, genererer systemet automatisk en ordresignatur. Brugeren betaler ordrebeløbet gennem USDT -blockchain og kan få mere end 0,6%af Kommissionen hver gang, og systemet distribuerer tilfældigt store belønningsordrer.",
    "ts2": "antydning",
    "ts3": "Utilstrækkelig balance",
    "ts4": "Gå til genopladning",
    "ts5": "Afbestille"
  },
  "income": {
    "totalRevenue": "Balance",
    "residualIncome": "provision",
    "claim": "modtage",
    "moduleTitle": "mit hold",
    "headcount": "samlede mennesker",
    "totalRecharge": "Total genopladning",
    "grade": "bruger",
    "numberOfPeople": "Bidrag (mængde)",
    "income": "stat",
    "viewDetails": "Se detaljer",
    "task_num": "Forslået",
    "task_num2": "afsluttet"
  },
  "revenueDetails": {
    "totalRevenue": "Samlede indtægter",
    "headcount": "samlede mennesker",
    "totalRecharge": "Total genopladning",
    "userLevel1": "Brugerniveau 1",
    "userLevel2": "Brugerniveau 2",
    "userLevel3": "Brugerniveau 3",
    "Nickname": "Nick Name",
    "phone": "Telefonnummer",
    "Recharge": "Top op",
    "income": "indkomst",
    "date": "Registreringstid"
  },
  "user": {
    "Myaccount": "min konto",
    "Withdrawal": "trække sig tilbage",
    "Recharge": "Top op",
    "InviteFriend": "Inviter dine venner",
    "InviteFriendTxt": "For hver fortjeneste, der er tjent af venner, får du den tilsvarende proportionskommission",
    "InviteFriendBtn": "Inviter nu",
    "MyInformation": "personlig information",
    "FundsDetails": "Kapitaloplysninger",
    "RechargeRecord": "Genopladning",
    "WithdrawalRecord": "Tilbagetrækningsrekord",
    "Language": "Sprog",
    "Logout": "Log ud"
  },
  "Withdrawal": {
    "address": "Tilbagetrækningsadresse",
    "placeholder1": "Tilbagetrækningsadresse",
    "availableBalance": "Disponibel saldo",
    "placeholder2": "Tilbagetrækningsbeløb",
    "actualBalance": "Rent faktisk",
    "placeholder3": "Rent faktisk",
    "password": "adgangskode",
    "placeholder4": "Indtast venligst 6-16-bit-adgangskoden",
    "submit": "Indsend",
    "amount": "Ikke større end tilgængelige beløb",
    "w1": "Ekspeditionsgebyr",
    "ts1": "Få tilbagetrækningsadressen",
    "ts2": "Indtast tilbagetrækningsbeløbet"
  },
  "Recharge": {
    "qrcode": "SCAN -kodeopladning",
    "copy": "Klik for at kopiere",
    "cotakecarepy": "Bemærk, en relateret overvejelse er at bruge din skærm eller side fast ejendom",
    "ts1": "Genoplad beløb",
    "ts2": "Andet beløb",
    "ts3": "Jo da",
    "ts4": "Tilslut venligst tegnebogen først",
    "ts5": "Vælg genopladningsbeløbet",
    "ts6": "I genopladning ..."
  },
  "Invitefriends": {
    "inviteCode": "Invitations kode",
    "inviteTxt": "Inviter venner, nyd en stor indkomst, inviter venligst",
    "copy": "Klik for at kopiere",
    "Rebaterules": "Rabat",
    "RebaterulesMsg": "Følgende betingelser skal være opfyldt",
    "scale": "Regler for Kommissionens forhold er som følger",
    "Userlevel": "Brugerniveau",
    "Rules": "Herske",
    "Commissionratio": "Kommissionen",
    "ps": "Kommissionen er afgjort med USDT, som når som helst kan trækkes tilbage til din tegnebog"
  },
  "personalinformation": {
    "Nickname": "Nick Name",
    "phone": "konto",
    "Loginpassword": "login kodeord",
    "address": "Tilbagetrækningsadresse",
    "modify": "Revidere",
    "Confirm": "Jo da"
  },
  "fundDetails": {
    "amount": "Beløb",
    "revenue": "indkomst",
    "time": "tid",
    "withdrawal": "trække sig tilbage",
    "recharge": "Top op",
    "type": {
    "1": "Top op",
    "2": "trække sig tilbage",
    "3": "Kommissionens samling",
    "4": "indkomst",
    "5": "Trække sig tilbage",
    "6": "Verserende",
    "7": "afsluttet",
    "8": "Afvist"
  }
  },
  "language": {
    "placeholder": "Indtast venligst den internationale områdekode",
    "Confirm": "Jo da",
    "cancel": "Afbestille"
  },
  "request": {
    "loadingText": "Indlæser...",
    "Serverexception": "Server abnormiteter"
  },
  "newLang": { "a1": "Balancen er dårlig", "a2": "Nuværende opgavenummer","a3": "Gå først til den bindende tilbagetrækningsadresse","a4": "Utilstrækkelig balance", "a5": "Om man skal genoplade" },
  "newLang2": {
    "a1": "Kontakt kundeservice for at ændre for bindingsadressen for at ændre",
    "a2": "Brug for at genoplade",
    "a3": "Kontakt kundeservicen for at ændre adgangskoden"
  }
}
