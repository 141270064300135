export default {
  "logo": {
    "name": "Τικ Τοκ",
    "msg": "Κέντρο παραγγελιών Tiktok",
    "wz": "URL:"
  },
  "head": { "Language": "Ελληνικά" },
  "menu": {
    "home": "εξώφυλλο",
    "order": "Σειρά",
    "trading": "εμπορικές συναλλαγές",
    "earnings": "ομάδα",
    "mine": "χρήστης"
  },
  "back": {
    "appDownload": "Λήψη εφαρμογής",
    "help": "βοήθεια",
    "order": "Σειρά",
    "trading": "εμπορικές συναλλαγές",
    "income": "ομάδα",
    "revenueDetails": "Λεπτομέρεια",
    "Withdrawal": "αποσύρω",
    "Recharge": "ξεχειλίζω",
    "Invitefriends": "προσκαλέσετε τους φίλους",
    "personalinformation": "προσωπικές πληροφορίες",
    "gywm": "σχετικά με εμάς",
    "kf": "εξυπηρέτηση πελατών"
  },
  "loading": {
    "loadingText": "φόρτωση...",
    "finishedText": "ΟΧΙ πια",
    "loadingText2": "Ταιριάζει ...",
    "loadingLogin": "Συνδεθείτε ...",
    "loadingSign": "Κανω ΕΓΓΡΑΦΗ ...",
    "confirmOrder": "σε συναλλαγή ...",
    "ldg1": "Στην απόσυρση ..."
  },
  "Toast": { "copy": "Αναπαραγωγή", "ts": "Επιλέξτε τη γλώσσα" },
  "login": {
    "title": "Γειά σου!",
    "msg": "Συνδεθείτε στο λογαριασμό σας",
    "phone": "λογαριασμός",
    "phoneCode": "Κώδικας περιοχής",
    "password": "Κωδικός πρόσβασης",
    "remember": "Θυμήσου με",
    "forget": "Ξεχάστε τον κωδικό πρόσβασης;",
    "loginBtn": "Σύνδεση",
    "noAccount": "Χωρίς λογαριασμό;",
    "goRegister": "Κανω ΕΓΓΡΑΦΗ",
    "lg1": "Άλλη σύνδεση λογαριασμού"
  },
  "sign": {
    "phone": "Τηλεφωνικό νούμερο",
    "phoneCode": "Κώδικας περιοχής",
    "Nickname": "Παρατσούκλι",
    "code": "Κωδικός επαλήθευσης",
    "sendCode": "Στείλτε τον κωδικό επαλήθευσης",
    "password": "Κωδικός πρόσβασης",
    "confirmPassword": "Επιβεβαίωση Κωδικού",
    "recommendationCode": "Κωδικός σύστασης",
    "protocol": "Το μητρώο σημαίνει ότι συμφωνείτε με τους όρους και τις προϋποθέσεις μας",
    "registerBtn": "κανω ΕΓΓΡΑΦΗ",
    "Existing": "Υπάρχων λογαριασμός?",
    "goLogin": "Σύνδεση",
    "rule": {
      "phone": "Ο λογαριασμός δεν μπορεί να είναι άδειος, η μορφή λογαριασμού είναι λανθασμένη",
      "nickname": "Το ψευδώνυμο πρέπει να γεμίσει",
      "password": "Ο κωδικός πρόσβασης δεν μπορεί να είναι άδειος, ο κωδικός πρόσβασης είναι ελάχιστο 6 ψηφία και ο κωδικός πρόσβασης είναι ασυνεπής.",
      "code": "Ο κωδικός επαλήθευσης πρέπει να συμπληρωθεί",
      "isAgree": "Ελέγξτε πρώτα τη συγκατάθεση"
    }
  },
  "home": {
    "cumulativeIncome": "Σωρευτικό εισόδημα",
    "notification": "κοινοποιώ",
    "moduleTitle1": "Η υπηρεσία μου",
    "moduleTitle2": "Εταίρος",
    "title": "κοινοποιώ"
  },
  "download": {
    "TIKTOKSHOP": "Κατάστημα Tiktok",
    "AndroidDownload": "Λήψη Android",
    "iOSDownload": "Λήψη μήλου"
  },
  "help": {
    "bz2": "κοινό πρόβλημα",
    "bz3": "Κανόνες διαπραγμάτευσης",
    "bz4": "Πώς να αποσύρετε χρήματα",
    "bz5": "Πώς να επαναφορτίσετε",
    "bz6": "1. Τι istiktokshop;",
    "bz7": "Το Tiktokshop είναι μια εταιρεία προώθησης μάρκετινγκ που βοηθά τους εμπόρους ηλεκτρονικών καταστημάτων Tiktokshop από όλο τον κόσμο για να αποκτήσουν περισσότερες πωλήσεις παραγγελιών και να αυξήσουν τα δεδομένα προβολής από τα ηλεκτρονικά καταστήματα Tiktokshop. Δεσμευόμαστε να δημιουργήσουμε ένα μοντέλο μάρκετινγκ κέρδους τριών μερών μεταξύ του Tiktokshop και των εμπόρων και των καταναλωτών. Συνδυάζοντας την τελευταία τεχνολογία Blockchain P2P, συνδέστε γρήγορα τους καταναλωτές και τους εμπόρους μέσω του USDT (TRC20, ERC20). Οι εγγεγραμμένοι χρήστες μπορούν να λάβουν την Επιτροπή Παραγγελιών, ενώ οι έμποροι μπορούν να αυξήσουν τα δεδομένα πωλήσεων του καταστήματος. Είναι το τελευταίο μοντέλο κέρδους στο μοντέλο Blockchain Internet!",
    "bz8": "2. Πώς να εργαστείτε στο OntikTokshop;",
    "bz9": "Μέσα από την καθημερινή ανατροφοδότηση του Tiktokshop, πρέπει να βελτιωθούν τα προϊόντα που πρέπει να βελτιωθούν στις πωλήσεις Tiktokshop. Οι χρήστες πληρώνουν το ποσό της παραγγελίας μέσω του blockchain USDT και λαμβάνουν καθημερινές προμήθειες.",
    "bz10": "3. Γιατί υπάρχει διαφορά στην τιμή του ίδιου νομίσματος στις δύο συναλλαγές;",
    "bz11": "Οι διαφορές των τιμών προκαλούνται από όλους τους παράγοντες που δεν ευνοούν την ελευθερία του νομίσματος, συμπεριλαμβανομένης της ταχύτητας μεταφοράς νομισμάτων, των συνθηκών δικτύου, των περιορισμών πρόσβασης στο νόμισμα, των ανθρώπων σε διάφορες περιοχές και ακόμη και των τύπων συναλλαγών και των συναλλαγών που παρέχονται από την ανταλλαγή. Ως εκ τούτου, το ίδιο νόμισμα μπορεί να προκαλέσει διαφορές τιμών σε διαφορετικές συναλλαγές.",
    "bz12": "4. Επενδυτικό κέρδος;",
    "bz13": "Όσο υψηλότερη είναι η τιμή που παίρνετε, τόσο υψηλότερο είναι το κέρδος που παίρνετε. Ταυτόχρονα, το Tiktokshop διαθέτει τυχαία εντολές μεγάλης προμήθειας.",
    "bz14": "Κάθε μέρα, το σύστημα δημιουργεί αυτόματα και διανέμει 60 παραγγελίες βασικών προϊόντων στον χρήστη. Οι μεγάλες εντολές προμήθειας κατανέμονται τυχαία.",
    "bz15": "Το USDT μπορεί να αποσυρθεί μετά από 60 παραγγελίες. (Εάν οι 60 παραγγελίες δεν ολοκληρωθούν, το σύστημα θα σταματήσει αυτόματα μέχρι το πλεόνασμα της παραγγελίας της ημέρας)",
    "bz16": "Κάθε λογαριασμός μπορεί να δεσμευτεί μόνο σε λογαριασμό USDT.",
    "bz17": "Μετά την ολοκλήρωση της καθημερινής παράδοσης, μπορείτε να αποσυρθείτε κανονικά.",
    "bz18": "Πριν από την επαναφόρτιση, δεσμεύετε τη διεύθυνση απόσυρσης (υποστήριξη TRC-20) USDT.",
  "bz19": "Υπάρχουν απαγορευμένες συμπεριφορές όπως η αμοιβαία επαναφόρτιση ή οι τραπεζικές μεταφορές και οι μεταφορές ανταλλαγής μεταξύ σχετικών λογαριασμών."

  },
  "order": {
    "incomplete": "κατεστραμένος",
    "completed": "ολοκληρώθηκε το",
    "orderNumber": "αριθμός παραγγελίας",
    "transactionTime": "ώρα συναλλαγής",
    "price": "Ποσό",
    "expectedReturns": "Αναμενόμενη επιστροφή",
    "submit": "Υποβάλετε την παραγγελία"
  },
  "trading": {
    "accountBalance": "Υπόλοιπο λογαριασμού",
    "introduce": "Εισαγωγή συναλλαγής",
    "freeze": "Συμπληρωμένος αριθμός",
    "walletBalance": "Συνολικό έργο",
    "startMatching": "Ξεκινήστε την αντιστοίχιση",
    "yield": "Τα σημερινά οφέλη",
    "orderNumber": "αριθμός παραγγελίας",
    "transactionTime": "ώρα συναλλαγής",
    "price": "Ποσό",
    "expectedReturns": "Αναμενόμενη επιστροφή",
    "startTrading": "Ξεκινήστε τις συναλλαγές",
    "cancel": "Ματαίωση",
    "ts": "Ανεπαρκής ισορροπία, ακόμα φτωχή",
    "msg": "Το Tiktokshop χρησιμοποιεί ανατροφοδότηση από το Tiktokshop κάθε μέρα για την προβολή προϊόντων που πρέπει να βελτιωθούν για τις πωλήσεις Tiktokshop. Εάν ο χρήστης χρειάζεται μόνο να κάνει κλικ στην παραγγελία, το σύστημα θα δημιουργήσει αυτόματα μια υπογραφή παραγγελίας. Ο χρήστης πληρώνει το ποσό της παραγγελίας μέσω του blockchain USDT και μπορεί να πάρει περισσότερο από το 0,6%της Επιτροπής κάθε φορά και το σύστημα διανέμει τυχαία μεγάλες παραγγελίες ανταμοιβής.",
    "ts2": "ίχνος",
    "ts3": "Ανεπαρκής ισορροπία",
    "ts4": "Πηγαίνετε στην επαναφόρτιση",
    "ts5": "Ματαίωση"
  },
  "income": {
    "totalRevenue": "Ισορροπία",
    "residualIncome": "επιτροπή",
    "claim": "λαμβάνω",
    "moduleTitle": "η ομάδα μου",
    "headcount": "συνολικά άτομα",
    "totalRecharge": "Συνολική επαναφόρτιση",
    "grade": "χρήστης",
    "numberOfPeople": "Συμβολή (ποσότητα)",
    "income": "κατάσταση",
    "viewDetails": "Δείτε λεπτομέρειες",
    "task_num": "κατεστραμένος",
    "task_num2": "ολοκληρώθηκε το"
  },
  "revenueDetails": {
    "totalRevenue": "Τα συνολικά έσοδα",
    "headcount": "συνολικά άτομα",
    "totalRecharge": "Συνολική επαναφόρτιση",
    "userLevel1": "Επίπεδο χρήστη 1",
    "userLevel2": "Επίπεδο χρήστη 2",
    "userLevel3": "Επίπεδο χρήστη 3",
    "Nickname": "Παρατσούκλι",
    "phone": "Τηλεφωνικό νούμερο",
    "Recharge": "ξεχειλίζω",
    "income": "εισόδημα",
    "date": "Ώρα εγγραφής"
  },
  "user": {
    "Myaccount": "ο λογαριασμός μου",
    "Withdrawal": "αποσύρω",
    "Recharge": "ξεχειλίζω",
    "InviteFriend": "Κάλεσε τους φίλους σου",
    "InviteFriendTxt": "Για κάθε κέρδος που κερδίζεται από φίλους, θα λάβετε την αντίστοιχη επιτροπή αναλογίας",
    "InviteFriendBtn": "Προσκαλέστε τώρα",
    "MyInformation": "προσωπικές πληροφορίες",
    "FundsDetails": "Λεπτομέρειες κεφαλαίου",
    "RechargeRecord": "Επαναφορά αρχείου",
    "WithdrawalRecord": "Αναλήψεις εγγραφής",
    "Language": "Γλώσσα",
    "Logout": "αποσύνδεση"
  },
  "Withdrawal": {
    "address": "Διεύθυνση απόσυρσης",
    "placeholder1": "Διεύθυνση απόσυρσης",
    "availableBalance": "διαθέσιμο υπόλοιπο",
    "placeholder2": "Ποσό απόσυρσης",
    "actualBalance": "Πράγματι",
    "placeholder3": "Πράγματι",
    "password": "Κωδικός πρόσβασης",
    "placeholder4": "Εισαγάγετε τον κωδικό πρόσβασης 6-16-bit",
    "submit": "υποβάλλουν",
    "amount": "Δεν είναι μεγαλύτερα από τα διαθέσιμα ποσά",
    "w1": "Αμοιβή χειρισμού",
    "ts1": "Λάβετε τη διεύθυνση απόσυρσης",
    "ts2": "Εισαγάγετε το ποσό απόσυρσης"
  },
  "Recharge": {
    "qrcode": "Επαναφόρτιση κώδικα σάρωσης",
    "copy": "Κάντε κλικ για αντιγραφή",
    "cotakecarepy": "Σημείωση, μια σχετική σκέψη είναι να χρησιμοποιήσετε την οθόνη ή τη σελίδα σας ακίνητη περιουσία",
    "ts1": "Επαναφόρτιση",
    "ts2": "Άλλο ποσό",
    "ts3": "Σίγουρος",
    "ts4": "Συνδέστε πρώτα το πορτοφόλι",
    "ts5": "Επιλέξτε το ποσό επαναφόρτισης",
    "ts6": "Σε επαναφόρτιση ..."
  },
  "Invitefriends": {
    "inviteCode": "Κωδικός πρόσκλησης",
    "inviteTxt": "Προσκαλέστε φίλους, απολαύστε ένα μεγάλο ποσό εισοδήματος, προσκαλέστε",
    "copy": "Κάντε κλικ για αντιγραφή",
    "Rebaterules": "Εκπτωση",
    "RebaterulesMsg": "Πρέπει να πληρούνται οι ακόλουθες συνθήκες",
    "scale": "Ο δείκτης κανόνων των επιτροπών είναι οι εξής",
    "Userlevel": "επίπεδο χρήστη",
    "Rules": "κανόνας",
    "Commissionratio": "Επιτόκιο",
    "ps": "Η Επιτροπή διευθετείται με USDT, το οποίο μπορεί να αποσυρθεί στο πορτοφόλι σας ανά πάσα στιγμή"
  },
  "personalinformation": {
    "Nickname": "Παρατσούκλι",
    "phone": "λογαριασμός",
    "Loginpassword": "κωδικός σύνδεσης",
    "address": "Διεύθυνση απόσυρσης",
    "modify": "Αναθεωρώ",
    "Confirm": "Σίγουρος"
  },
  "fundDetails": {
    "amount": "Ποσό",
    "revenue": "εισόδημα",
    "time": "χρόνος",
    "withdrawal": "αποσύρω",
    "recharge": "ξεχειλίζω",
    "type": {
    "1": "ξεχειλίζω",
    "2": "αποσύρω",
    "3": "Συλλογή προμήθειας",
    "4": "εισόδημα",
    "5": "Αποσύρω",
    "6": "εκκρεμής",
    "7": "ολοκληρώθηκε το",
    "8": "απορριπτόμενος"
  }
  },
  "language": {
    "placeholder": "Εισαγάγετε τον διεθνή κωδικό περιοχής",
    "Confirm": "Σίγουρος",
    "cancel": "Ματαίωση"
  },
  "request": {
    "loadingText": "φόρτωση...",
    "Serverexception": "Ανωμαλίες διακομιστή"
  },
  "newLang": {
    "a1": "Η ισορροπία είναι φτωχή",
    "a2": "Τρέχων αριθμός εργασιών",
    "a3": "Μεταβείτε στη διεύθυνση δέσμευσης πρώτα",
    "a4": "Ανεπαρκής ισορροπία", 
    "a5": "Αν πρέπει να επαναφορτίσετε"
  },
  "newLang2": {
    "a1": "Για τη διεύθυνση δέσμευσης, παρακαλούμε επικοινωνήστε με την τροποποίηση της εξυπηρέτησης πελατών για να τροποποιήσετε",
    "a2": "Πρέπει να επαναφορτίσετε",
    "a3": "Επικοινωνήστε με την εξυπηρέτηση πελατών για να τροποποιήσετε τον κωδικό πρόσβασης"
  }
}
