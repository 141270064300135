import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

//  引入需要的语言包
import en from "@/lang/en.js";
import zh from "@/lang/zh.js";
import ru from "@/lang/ru.js";
import ae from "@/lang/ar.js";
import bn from "@/lang/bn.js";
import cs from "@/lang/cs.js";
import da from "@/lang/da.js";
import de from "@/lang/de.js";
import el from "@/lang/el.js";
import es from "@/lang/es.js";
import it from "@/lang/it.js";
import jp from "@/lang/ja.js";
import kr from "@/lang/ko.js";
import nl from "@/lang/nl.js";
import pl from "@/lang/pl.js";
import ro from "@/lang/ro.js";
import sk from "@/lang/sk.js";
import tr from "@/lang/tr.js";

let json = JSON.parse(localStorage.getItem('store'))||{}
// 本地语言环境获取
const lang = json.language || 'en-us'

const i18n = new VueI18n({
  locale: lang, // 语言标识
  fallbackLocale: "en-us", // 失败时默认语言
  silentTranslationWarn: true, // 设置为true 会屏蔽翻译在控制台报出的警告
  messages: {
    'en-us': en,
    'zh-cn': zh,
    'ru-ru': ru,
    'ar-ae': ae,
    'bn-bn': bn,
    'cs-cz': cs,
    'da-dk': da,
    'de-de': de,
    'el-gr': el,
    'es-es': es,
    'it-it': it,
    'ja-jp': jp,
    'ko-kr': kr,
    'nl-nl': nl,
    'pl-pl': pl,
    'ro-ro': ro,
    'sk-sk': sk,
    'tr-tr': tr,
  },
});

export default i18n;

