import Vue from 'vue';
import { Button,NoticeBar,Collapse, CollapseItem,NavBar,Dialog,List,Cell,Icon,ActionSheet,Toast,Swipe,SwipeItem,Lazyload,Popup,Picker,Badge} from 'vant';

Vue.use(Button);
Vue.use(NoticeBar);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(List);
Vue.use(Cell);
Vue.use(ActionSheet);
Vue.use(Icon);
Vue.use(Toast);
// Vue.use(Form);
// Vue.use(Field);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Badge);


Vue.prototype.$Toast = Toast
Vue.prototype.$Dialog = Dialog


// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
