export default {
  "logo": {
    "name": "TIC-tac",
    "msg": "Centrul de comandă Tiktok",
    "wz": "URL:"
  },
  "head": { "Language": "Română" },
  "menu": {
    "home": "prima pagina",
    "order": "Ordin",
    "trading": "comerț",
    "earnings": "echipă",
    "mine": "utilizator"
  },
  "back": {
    "appDownload": "Descărcare de aplicații",
    "help": "Ajutor",
    "order": "Ordin",
    "trading": "comerț",
    "income": "echipă",
    "revenueDetails": "Detaliu",
    "Withdrawal": "retrage",
    "Recharge": "umple",
    "Invitefriends": "invita prieteni",
    "personalinformation": "Informații personale",
    "gywm": "despre noi",
    "kf": "serviciu clienți"
  },
  "loading": {
    "loadingText": "Se încarcă...",
    "finishedText": "Nu mai",
    "loadingText2": "Potrivirea ...",
    "loadingLogin": "autentificare...",
    "loadingSign": "Inregistreaza-te ...",
    "confirmOrder": "În tranzacție ...",
    "ldg1": "În retragere ..."
  },
  "Toast": { "copy": "Replicare", "ts": "Vă rugăm să selectați limba" },
  "login": {
    "title": "Buna ziua!",
    "msg": "Conecteaza-te la contul tau",
    "phone": "cont",
    "phoneCode": "Codul regiunii",
    "password": "parola",
    "remember": "Ține-mă minte",
    "forget": "Uitați parola?",
    "loginBtn": "Log in",
    "noAccount": "Fără cont?",
    "goRegister": "Inregistreaza-te",
    "lg1": "Alte autentificare a contului"
  },
  "sign": {
    "phone": "Număr de telefon",
    "phoneCode": "Codul regiunii",
    "Nickname": "Poreclă",
    "code": "Cod de verificare",
    "sendCode": "Trimiteți codul de verificare",
    "password": "parola",
    "confirmPassword": "Confirmă parola",
    "recommendationCode": "Cod de recomandare",
    "protocol": "Înregistrare înseamnă că sunteți de acord cu termenii și condițiile noastre",
    "registerBtn": "Inregistreaza-te",
    "Existing": "Cont existent?",
    "goLogin": "Log in",
    "rule": {
      "phone": "Contul nu poate fi gol, formatul contului este incorect",
      "nickname": "Porecla ar trebui să fie umplută",
      "password": "Parola nu poate fi goală, parola este minimă de 6 cifre, iar parola este inconsistentă.",
      "code": "Codul de verificare trebuie completat",
      "isAgree": "Vă rugăm să verificați mai întâi consimțământul"
    }
  },
  "home": {
    "cumulativeIncome": "Venit cumulativ",
    "notification": "notifică",
    "moduleTitle1": "Serviciul meu",
    "moduleTitle2": "Partener",
    "title": "notifică"
  },
  "download": {
    "TIKTOKSHOP": "Magazin Tiktok",
    "AndroidDownload": "Descărcare Android",
    "iOSDownload": "Descărcare Apple"
  },
  "help": {
    "bz2": "Problemă comună",
    "bz3": "Reguli de tranzacționare",
    "bz4": "Cum să retrageți bani",
    "bz5": "Cum să reîncărcați",
    "bz6": "1. Ce estektokshop?",
    "bz7": "Tiktokshop este o companie de promovare a marketingului care ajută Tiktokshop online comercianți din toată lumea din întreaga lume să obțină mai multe vânzări de comenzi și să crească datele de vizionare din magazinele online Tiktokshop. Ne -am angajat să stabilim un model de marketing de profit în trei partide între Tiktokshop și comercianți și consumatori. Combinând cea mai recentă tehnologie blockchain P2P, conectați rapid consumatorii și comercianții prin USDT (TRC20, ERC20). Utilizatorii înregistrați pot primi comisia de comenzi, în timp ce comercianții pot crește datele de vânzări ale magazinului. Este cel mai recent model de profit din modelul de blockchain de internet!",
    "bz8": "2. Cum să lucrezi ontiktokshop?",
    "bz9": "Prin feedback -ul zilnic al Tiktokshop, produsele care trebuie îmbunătățite la vânzările Tiktokshop trebuie îmbunătățite. Utilizatorii plătesc suma comenzii prin Blockchain USDT și primesc comisii zilnice.",
    "bz10": "3. De ce există o diferență în prețul aceleiași monede în cele două tranzacții?",
    "bz11": "Diferențele de preț sunt cauzate de toți factorii care nu sunt favorabile libertății de monedă, inclusiv viteza de transfer valutar, condițiile de rețea, restricțiile de acces în valută, persoanele din diferite regiuni și chiar tipurile de tranzacționare și tranzacțiile furnizate de schimb. Prin urmare, aceeași monedă poate provoca diferențe de preț în tranzacții diferite.",
    "bz12": "4. Profitul investițiilor?",
    "bz13": "Cu cât este mai mare prețul pe care îl obțineți, cu atât este mai mare profitul pe care îl obțineți. În același timp, Tiktokshop alocă la întâmplare comenzi mari de comision.",
    "bz14": "În fiecare zi, sistemul generează automat și distribuie 60 de comenzi de mărfuri către utilizator. Comenzile de comision mari sunt distribuite la întâmplare.",
    "bz15": "USDT poate fi retras după 60 de comenzi. (Dacă cele 60 de comenzi nu sunt finalizate, sistemul se va opri automat până la cantitatea de comandă excedentară a zilei)",
    "bz16": "Fiecare cont poate fi obligat doar la un cont USDT.",
    "bz17": "După finalizarea livrării zilnice a comenzii, vă puteți retrage în mod normal.",
    "bz18": "Înainte de reîncărcare, vă rugăm să vă legați adresa de retragere (asistență TRC-20).",
  "bz19": "Există comportamente interzise, ​​cum ar fi reîncărcarea reciprocă sau transferurile bancare și transferurile de schimb între conturile conexe."

  },
  "order": {
    "incomplete": "descurajat",
    "completed": "efectuat",
    "orderNumber": "număr de ordine",
    "transactionTime": "Ora tranzacției",
    "price": "Cantitate",
    "expectedReturns": "Revenirea preconizată",
    "submit": "Depune comanda"
  },
  "trading": {
    "accountBalance": "Balanța contului",
    "introduce": "Introducerea tranzacțiilor",
    "freeze": "Numărul completat",
    "walletBalance": "Sarcină totală",
    "startMatching": "Începe să se potrivească",
    "yield": "Beneficiile de astăzi",
    "orderNumber": "număr de ordine",
    "transactionTime": "Ora tranzacției",
    "price": "Cantitate",
    "expectedReturns": "Revenirea preconizată",
    "startTrading": "Începeți tranzacționarea",
    "cancel": "Anulare",
    "ts": "Echilibru insuficient, încă sărac",
    "msg": "Tiktokshop folosește feedback de la Tiktokshop în fiecare zi pentru a afișa produse care trebuie îmbunătățite pentru vânzările Tiktokshop. Dacă utilizatorul trebuie doar să facă clic pe comandă, sistemul va genera automat o semnătură de comandă. Utilizatorul plătește suma comenzii prin blockchain USDT și poate obține mai mult de 0,6%din comisie de fiecare dată, iar sistemul distribuie la întâmplare comenzi mari de recompensă.",
    "ts2": "aluzie",
    "ts3": "Echilibru insuficient",
    "ts4": "Du -te la reîncărcare",
    "ts5": "Anulare"
  },
  "income": {
    "totalRevenue": "Echilibru",
    "residualIncome": "comision",
    "claim": "a primi",
    "moduleTitle": "echipa mea",
    "headcount": "Total persoane",
    "totalRecharge": "Reîncărcare totală",
    "grade": "utilizator",
    "numberOfPeople": "Contribuție (cantitate)",
    "income": "stat",
    "viewDetails": "Vezi detalii",
    "task_num": "descurajat",
    "task_num2": "efectuat"
  },
  "revenueDetails": {
    "totalRevenue": "Veniturile totale",
    "headcount": "Total persoane",
    "totalRecharge": "Reîncărcare totală",
    "userLevel1": "Nivelul utilizatorului 1",
    "userLevel2": "Nivelul utilizatorului 2",
    "userLevel3": "Nivelul utilizatorului 3",
    "Nickname": "Poreclă",
    "phone": "Număr de telefon",
    "Recharge": "umple",
    "income": "sursa de venit",
    "date": "Timp de înregistrare"
  },
  "user": {
    "Myaccount": "Contul meu",
    "Withdrawal": "retrage",
    "Recharge": "umple",
    "InviteFriend": "Invita-ti prietenii",
    "InviteFriendTxt": "Pentru fiecare profit obținut de prieteni, veți primi comisia de proporție corespunzătoare",
    "InviteFriendBtn": "Invitați acum",
    "MyInformation": "Informații personale",
    "FundsDetails": "Detalii capitale",
    "RechargeRecord": "Înregistrare de reîncărcare",
    "WithdrawalRecord": "Registrul de retragere",
    "Language": "limba",
    "Logout": "sign out"
  },
  "Withdrawal": {
    "address": "Adresa de retragere",
    "placeholder1": "Adresa de retragere",
    "availableBalance": "Sold disponibil",
    "placeholder2": "Suma de retragere",
    "actualBalance": "De fapt",
    "placeholder3": "De fapt",
    "password": "parola",
    "placeholder4": "Vă rugăm să introduceți parola pe 6-16 biți",
    "submit": "Trimite",
    "amount": "Nu mai mari decât sumele disponibile",
    "w1": "Taxa de manevrare",
    "ts1": "Vă rugăm să obțineți adresa de retragere",
    "ts2": "Vă rugăm să introduceți suma de retragere"
  },
  "Recharge": {
    "qrcode": "Reîncărcarea codului de scanare",
    "copy": "Faceți clic pentru a copia",
    "cotakecarepy": "Rețineți, o considerație conexă este să utilizați ecranul sau paginile imobiliare",
    "ts1": "Reîncărcați suma",
    "ts2": "Altă sumă",
    "ts3": "Sigur",
    "ts4": "Vă rugăm să conectați mai întâi portofelul",
    "ts5": "Vă rugăm să selectați suma de reîncărcare",
    "ts6": "În reîncărcare ..."
  },
  "Invitefriends": {
    "inviteCode": "Codul de invitatie",
    "inviteTxt": "Invitați prieteni, bucurați -vă de o cantitate mare de venit, vă rugăm să invitați",
    "copy": "Faceți clic pentru a copia",
    "Rebaterules": "Rabat",
    "RebaterulesMsg": "Trebuie îndeplinite următoarele condiții",
    "scale": "Regulile raportului Comisiei sunt următoarele",
    "Userlevel": "nivel de utilizator",
    "Rules": "regulă",
    "Commissionratio": "Rata comisionului",
    "ps": "Comisia este soluționată cu USDT, care poate fi retrasă în portofel în orice moment"
  },
  "personalinformation": {
    "Nickname": "Poreclă",
    "phone": "cont",
    "Loginpassword": "Parola de logare",
    "address": "Adresa de retragere",
    "modify": "Revizui",
    "Confirm": "Sigur"
  },
  "fundDetails": {
    "amount": "Cantitate",
    "revenue": "sursa de venit",
    "time": "timp",
    "withdrawal": "retrage",
    "recharge": "umple",
    "type": {
    "1": "umple",
    "2": "retrage",
    "3": "Colectarea comisiei",
    "4": "sursa de venit",
    "5": "Retrage",
    "6": "In asteptarea",
    "7": "efectuat",
    "8": "respins"
  }
  },
  "language": {
    "placeholder": "Vă rugăm să introduceți codul internațional de zonă",
    "Confirm": "Sigur",
    "cancel": "Anulare"
  },
  "request": {
    "loadingText": "Se încarcă...",
    "Serverexception": "Anomalii serverului"
  },
  "newLang": {
    "a1": "Echilibrul este slab",
    "a2": "Numărul actual al sarcinii",
    "a3": "Vă rugăm să accesați mai întâi adresa de retragere obligatorie",
    "a4": "Echilibru insuficient", 
    "a5": "Dacă să reîncărcați"
  },
  "newLang2": {
    "a1": "Pentru adresa de legare, vă rugăm să contactați Serviciul Clienți pentru a modifica",
    "a2": "Trebuie să vă reîncărcați" ,
    "a3": "Vă rugăm să contactați serviciul pentru clienți pentru a modifica parola"
  },
  
}
