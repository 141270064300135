export default {
  "logo": {
    "name": "Tik Tok",
    "msg": "Centro de pedido de Tiktok",
    "wz": "URL:"
  },
  "head": { "Language": "español" },
  "menu": {
    "home": "página delantera",
    "order": "Orden",
    "trading": "comercio",
    "earnings": "equipo",
    "mine": "usuario"
  },
  "back": {
    "appDownload": "Descarga de la aplicación",
    "help": "ayuda",
    "order": "Orden",
    "trading": "comercio",
    "income": "equipo",
    "revenueDetails": "Detalle",
    "Withdrawal": "retirar",
    "Recharge": "completar",
    "Invitefriends": "invitar a amigos",
    "personalinformation": "informacion personal",
    "gywm": "sobre nosotros",
    "kf": "Servicio al Cliente"
  },
  "loading": {
    "loadingText": "cargando...",
    "finishedText": "No más",
    "loadingText2": "Coincidente ...",
    "loadingLogin": "Iniciando sesión...",
    "loadingSign": "Registro ...",
    "confirmOrder": "en transacción ...",
    "ldg1": "En el retiro ..."
  },
  "Toast": { "copy": "Replicación", "ts": "Seleccione el idioma" },
  "login": {
    "title": "¡Hola!",
    "msg": "Ingrese a su cuenta",
    "phone": "cuenta",
    "phoneCode": "Código de región",
    "password": "contraseña",
    "remember": "Acuérdate de mí",
    "forget": "¿Olvidas la contraseña?",
    "loginBtn": "Acceso",
    "noAccount": "¿Sin cuenta?",
    "goRegister": "Registro",
    "lg1": "Otra cuenta inicio de sesión"
  },
  "sign": {
    "phone": "Número de teléfono",
    "phoneCode": "Código de región",
    "Nickname": "Apodo",
    "code": "Código de verificación",
    "sendCode": "Enviar el código de verificación",
    "password": "contraseña",
    "confirmPassword": "confirmar Contraseña",
    "recommendationCode": "Código de recomendación",
    "protocol": "Registrarse significa que está de acuerdo con nuestros términos y condiciones",
    "registerBtn": "registro",
    "Existing": "¿Cuenta existente?",
    "goLogin": "Acceso",
    "rule": {
      "phone": "La cuenta no puede estar vacía, el formato de la cuenta es incorrecto",
      "nickname": "El apodo debe llenarse",
      "password": "La contraseña no puede estar vacía, la contraseña es mínima de 6 dígitos y la contraseña es inconsistente.",
      "code": "Se debe llenar el código de verificación",
      "isAgree": "Por favor verifique el consentimiento primero"
    }
  },
  "home": {
    "cumulativeIncome": "Ingreso acumulativo",
    "notification": "notificar",
    "moduleTitle1": "Mi servicio",
    "moduleTitle2": "Pareja",
    "title": "notificar"
  },
  "download": {
    "TIKTOKSHOP": "Tienda tiktok",
    "AndroidDownload": "Descarga de Android",
    "iOSDownload": "Descarga de Apple"
  },
  "help": {
    "bz2": "problema comun",
    "bz3": "Reglas comerciales",
    "bz4": "Cómo retirar dinero",
    "bz5": "Cómo recargar",
    "bz6": "1. ¿Qué istiktokshop?",
    "bz7": "Tiktokshop es una compañía de promoción de marketing que ayuda a los comerciantes de tiendas en línea de Tiktokshop de todo el mundo a obtener más ventas de pedidos y aumentar los datos de visualización de las tiendas en línea de Tiktokshop. Estamos comprometidos a establecer un modelo de marketing de ganancias de tres partidos entre Tiktokshop y comerciantes y consumidores. Combinando la última tecnología P2P Blockchain, conecte rápidamente a los consumidores y comerciantes a través de USDT (TRC20, ERC20). Los usuarios registrados pueden obtener la comisión de pedidos, mientras que los comerciantes pueden aumentar los datos de ventas de la tienda. ¡Es el último modelo de ganancias en el modelo de blockchain de Internet!",
    "bz8": "2. ¿Cómo trabajar en Ttiktokshop?",
    "bz9": "A través de la retroalimentación diaria de Tiktokshop, los productos que deben mejorarse en las ventas de Tiktokshop deben mejorarse solo para hacer clic fácilmente en el pedido para generar automáticamente suscripciones de pedidos. Los usuarios pagan el monto del pedido a través del USDT blockchain y obtienen comisiones diarias.",
    "bz10": "3. ¿Por qué hay una diferencia en el precio de la misma moneda en las dos transacciones?",
    "bz11": "Las diferencias de precios son causadas por todos los factores que no conducen a la libertad de moneda, incluida la velocidad de la transferencia de divisas, las condiciones de la red, las restricciones de acceso a la moneda, las personas en diferentes regiones e incluso los tipos de negociación y las transacciones proporcionadas por el intercambio. Por lo tanto, la misma moneda puede causar diferencias de precios en diferentes transacciones.",
    "bz12": "4. ¿Ganancias de inversión?",
    "bz13": "Cuanto mayor sea el precio que obtenga, mayor será el beneficio que obtenga. Al mismo tiempo, Tiktokshop asigna al azar las grandes órdenes de comisiones.",
    "bz14": "Todos los días, el sistema genera automáticamente y distribuye 60 pedidos de productos básicos al usuario. Las grandes comisiones se distribuyen al azar.",
    "bz15": "El USDT se puede retirar después de 60 pedidos. (Si no se completan los 60 pedidos, el sistema se detendrá automáticamente hasta el monto del orden excedente del día)",
    "bz16": "Cada cuenta solo puede estar vinculada a una cuenta de USDT.",
    "bz17": "Después de completar la entrega de pedidos diarios, puede retirar normalmente.",
    "bz18": "Antes de recarga, vincule su dirección de retiro (soporte TRC-20) USDT.",
  "bz19": "Hay comportamientos prohibidos como la recarga mutua o las transferencias bancarias, y las transferencias de intercambio entre las cuentas relacionadas."

  },
  "order": {
    "incomplete": "deshecho",
    "completed": "terminado",
    "orderNumber": "número de orden",
    "transactionTime": "hora de transacción",
    "price": "Cantidad",
    "expectedReturns": "Rendimiento esperado",
    "submit": "Orden de envio"
  },
  "trading": {
    "accountBalance": "Saldo de la cuenta",
    "introduce": "Introducción de transacción",
    "freeze": "Número completado",
    "walletBalance": "Tarea total",
    "startMatching": "Empezar a coincidir",
    "yield": "Los beneficios de hoy",
    "orderNumber": "número de orden",
    "transactionTime": "hora de transacción",
    "price": "Cantidad",
    "expectedReturns": "Rendimiento esperado",
    "startTrading": "Comienza a negociar",
    "cancel": "Cancelar",
    "ts": "Equilibrio insuficiente, todavía pobre",
    "msg": "Tiktokshop utiliza comentarios de Tiktokshop todos los días para mostrar productos que deben mejorarse para las ventas de Tiktokshop. Si el usuario solo necesita hacer clic en el pedido, el sistema generará automáticamente una firma de pedido. El usuario paga el monto del pedido a través de la cadena de bloques USDT, y puede obtener más del 0.6%de la comisión cada vez, y el sistema distribuye al azar grandes órdenes de recompensa.",
    "ts2": "pista",
    "ts3": "Saldo insuficiente",
    "ts4": "Ir a recarga",
    "ts5": "Cancelar"
  },
  "income": {
    "totalRevenue": "Balance",
    "residualIncome": "comisión",
    "claim": "recibir",
    "moduleTitle": "mi equipo",
    "headcount": "Total de personas",
    "totalRecharge": "Recarga total",
    "grade": "usuario",
    "numberOfPeople": "Contribución (cantidad)",
    "income": "estado",
    "viewDetails": "Ver detalles",
    "task_num": "deshecho",
    "task_num2": "terminado"
  },
  "revenueDetails": {
    "totalRevenue": "Los ingresos totales",
    "headcount": "Total de personas",
    "totalRecharge": "Recarga total",
    "userLevel1": "Nivel de usuario 1",
    "userLevel2": "Nivel de usuario 2",
    "userLevel3": "Nivel de usuario 3",
    "Nickname": "Apodo",
    "phone": "Número de teléfono",
    "Recharge": "completar",
    "income": "ingreso",
    "date": "Tiempo de registro"
  },
  "user": {
    "Myaccount": "mi cuenta",
    "Withdrawal": "retirar",
    "Recharge": "completar",
    "InviteFriend": "Invita a tus amigos",
    "InviteFriendTxt": "Por cada ganancia obtenida por amigos, obtendrá la comisión de proporción correspondiente",
    "InviteFriendBtn": "Invitar ahora",
    "MyInformation": "informacion personal",
    "FundsDetails": "Detalles de capital",
    "RechargeRecord": "Registro de recarga",
    "WithdrawalRecord": "Registro de retiros",
    "Language": "idioma",
    "Logout": "desconectar"
  },
  "Withdrawal": {
    "address": "Dirección de retiro",
    "placeholder1": "Dirección de retiro",
    "availableBalance": "Saldo disponible",
    "placeholder2": "Cantidad de retiro",
    "actualBalance": "De hecho",
    "placeholder3": "De hecho",
    "password": "contraseña",
    "placeholder4": "Ingrese la contraseña de 6-16 bits",
    "submit": "entregar",
    "amount": "No sean mayores que las cantidades disponibles",
    "w1": "Tasa de tramitación",
    "ts1": "Obtenga la dirección de retiro",
    "ts2": "Ingrese el monto del retiro"
  },
  "Recharge": {
    "qrcode": "Recarga del código de escaneo",
    "copy": "Haga clic para copiar",
    "cotakecarepy": "Nota, una consideración relacionada es usar su pantalla o página inmobiliaria",
    "ts1": "Cantidad de recarga",
    "ts2": "Otra cantidad",
    "ts3": "Seguro",
    "ts4": "Conecte primero la billetera",
    "ts5": "Seleccione la cantidad de recarga",
    "ts6": "En recarga ..."
  },
  "Invitefriends": {
    "inviteCode": "Código de invitación",
    "inviteTxt": "Invitar a amigos, disfrutar de una gran cantidad de ingresos, por favor invite",
    "copy": "Haga clic para copiar",
    "Rebaterules": "Reembolso",
    "RebaterulesMsg": "Se deben cumplir las siguientes condiciones",
    "scale": "Reglas de la relación de comisión son las siguientes",
    "Userlevel": "nivel de usuario",
    "Rules": "regla",
    "Commissionratio": "porcentaje de comision",
    "ps": "La comisión se resuelve con USDT, que se puede retirar a su billetera en cualquier momento"
  },
  "personalinformation": {
    "Nickname": "Apodo",
    "phone": "cuenta",
    "Loginpassword": "contraseña de inicio de sesión",
    "address": "Dirección de retiro",
    "modify": "Revisar",
    "Confirm": "Seguro"
  },
  "fundDetails": {
    "amount": "Cantidad",
    "revenue": "ingreso",
    "time": "tiempo",
    "withdrawal": "retirar",
    "recharge": "completar",
    "type": {
    "1": "completar",
    "2": "retirar",
    "3": "Colección de comisiones",
    "4": "ingreso",
    "5": "Retirar",
    "6": "Pendiente",
    "7": "terminado",
    "8": "rechazado"
  }
  },
  "language": {
    "placeholder": "Ingrese el código de área internacional",
    "Confirm": "Seguro",
    "cancel": "Cancelar"
  },
  "request": {
    "loadingText": "cargando...",
    "Serverexception": "Anormalidades del servidor"
  },
  "newLang": { "a1": "El equilibrio es pobre", "a2": "Número de tarea actual","a3": "Vaya primero a la dirección de retiro vinculante","a4": "Saldo insuficiente", "a5": "Si recargar" },
  "newLang2": {
    "a1": "Para la dirección vinculante, comuníquese con el servicio al cliente para modificar",
    "a2": "Necesito recargar",
    "a3": "Póngase en contacto con el servicio al cliente para modificar la contraseña"
  }
}
